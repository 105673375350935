<template>
  <tr :class="{'form-items__row-inactive': props.item.status == 'inactive'}">
    <wi-list-data-table-actions
      :config="config"
      :props="props"
    ></wi-list-data-table-actions>
    <td class="justify-left">
      <input type="text" :value="props.item.id">
    </td>
    <td class="justify-left">
      <div>
        <v-icon small>{{props.item.type.icon}}</v-icon>
        <strong style="padding-left: 5px;">{{props.item.type.title}}</strong>
      </div>
    </td>
    <td class="justify-left">
      {{props.item.label}}
    </td>
    <td class="justify-left">
      {{props.item.value}}
    </td>
    <td class="justify-left">
      <span v-if="props.item.active" class="form-items__status form-items__status--active">
        Ativo
      </span>
      <span v-else class="form-items__status form-items__status--inactive">
        Inativo
      </span>
    </td>
    <td class="justify-left">
      <v-btn outline small link :disabled="props.item.type.name != 'select'" :to="goToFormFieldOptions()">
        <v-icon small>format_list_bulleted</v-icon>
        <span> Opções</span>  
      </v-btn>
    </td>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'FormFieldOptions',
    methods: {
      goToFormFieldOptions: function () {
        return {
          name: 'wells-list',
          params: {
            page: 'well-config-form-field-option'
          },
          query: {
            where: 'form_field_id,' + this.props.item.id
          }
        }
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
<style scoped>
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
  .form-items__row-inactive {
    background: rgb(209, 209, 209);
  }
  .form-items__status {
    border-width: 1px;
    border-style: solid;
    padding: 2px 5px;
  }
  .form-items__status--active {
    color: #eceff1;
    background: rgb(4, 112, 0);
  }
  .form-items__status--inactive {
    color: grey;
    background: #eceff1;
  }
  .form-items__required {
    border-width: 1px;
    border-style: solid;
    padding: 2px 5px;
  }
  .form-items__required--active {
    color: #eceff1;
    background: rgb(190, 178, 0);
  }
  .form-items__required--inactive {
    color: grey;
    background: #eceff1;
  }
</style>